import React from 'react';

export default function CTASection() {
    return (
        <div className="bg-indigo-700">
            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    <span className="block">Foglalj online állatorvosi tanácsadást most!</span>
                </h2>

                <a
                    href="https://petwisecare.booked4.us/public/book"
                    target="_blank"
                    className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
                >
                    Időpontot foglalok!
                </a>
            </div>
        </div>
    )
}
