import React from 'react';

const people = [
    {
        name: 'dr. Piller Pálma',
        role: 'Állatorvos',
        imageUrl:
            '/img/header_image.webp',
        bio: 'Mindig is az állatok iránti rajongás határozta meg az életemet, és a segítségnyújtási vágy vezetett az állatorvosi hivatásra. Gyerekkori álmom vált valóra, amikor erre a pályára léphettem. Egyetemi éveim alatt több külföldi klinikán is megfordultam a minél sokrétűbb tapasztalatszerzés céljából, majd a diplomázásomat követően kutya-macska praxisban helyezkedtem el.\n' +
            '\n' +
            'Klinikai munkám mellett szívügyem az edukáció és a felelős állattartás terjesztése is. Szenvedélyem hogy naprakész ismeretekkel és tanácsokkal segítsem az állattartókat, hogy kedvenceiknek boldog és hosszú életet tudjanak biztosítani! \n' +
            '\n' +
            'Nagyon fontosnak tartom a betegségmegelőzést és az egészséges élet biztosítását kedvenceink számára is. Küldetésemnek érzem, hogy ebben is segítsem a gazdikat.',
    },
    {
        name: 'dr. Balatonyi Lilla',
        role: 'Állatorvos',
        imageUrl:
            '/img/balatonyi_lilla.webp',
        bio: ' Balatonyi Lilla vagyok, 2018-ban végeztem az Állatorvostudományi Egyetemen, általános állatorvosként. Gyakorlati tapasztalatot egy kutyákat és macskákat ellátó pécsi rendelőben szereztem, ahol a belgyógyászatra, a kisállat kardiológiára, és az aneszteziológiára fordítottam a legtöbb időm-energiám. Lassan három éve egy reaktív keverék kutya gazdájává váltam, azóta folyamatosan tanulok és képzem magam a viselkedési zavarokkal kapcsolatban is. \n' +
            "Az elmúlt években ezeken felül igyekeztem a lehető leghatékonyabban segíteni az állattartókat ismeretterjesztő podcastek, cikkek, interjúk megalkotásával, illetve gyermekeknek szánt edukációs könyvekkel a kutya-és macskatartásról. ",
    },

    {
        name: 'dr. Becker Zsolt',
        role: 'Állatorvos',
        imageUrl:
            '/img/becker_zsolt2.webp',
        bio: 'Egész életemben kutyák és macskák vettek körbe, nem is tudnám az életemet elképzelni nélkülük. Már az állatorvosi diplomám megszerzése előtt is igyekeztem tapasztalatot szerezni, nem csak kutyákkal és macskákkal, hanem egzotikus állatokkal (pl. nyúl, tengerimalac) kapcsolatban is. Fontosnak tartom nemcsak a kiváló szakmai ellátást, hanem az empatikus kommunikációt is, hiszen családtagokat látunk el.',
    },

    // More people...
]

export default function Team() {
    return (
        <div className="bg-white">
            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12">
                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Ismerd meg csapatunkat</h2>

                    <ul
                        role="list"
                        className="space-y-12 lg:grid lg:grid-cols-2 sm:grid-cols-1 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
                    >
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                                    <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                        <img className="object-cover object-top shadow-lg rounded-lg"
                                             src={person.imageUrl} alt=""/>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <div className="space-y-4">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>{person.name}</h3>
                                                <p className="text-indigo-600">{person.role}</p>
                                            </div>
                                            <div className="text-lg">
                                                <p className="text-gray-500">{person.bio}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
