import React from 'react';

export default function Testimonials() {
    return (
        <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <svg
                    className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    role="img"
                    aria-labelledby="svg-workcation"
                >
                    <title id="svg-workcation">Workcation</title>
                    <defs>
                        <pattern
                            id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"/>
                </svg>

                <div className="relative">
                    <div className="text-center">
                        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">Ajánlások</h2>
                    </div>


                    <blockquote className="mt-10">
                        <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                            <p>
                                &ldquo;Rengeteg kérdésem volt, miután örökbe fogadtam Bobbyt. Az online tanácsadáson
                                mindent alaposan át tudtunk beszélni, minden kérdésemre választ kaptam! Nagyon nagy
                                segítség volt, most már nyugodt vagyok, és tudom miket kell megadnom Bobbynak az
                                egészséges, hosszú élethez.&rdquo;
                            </p>
                        </div>
                        <footer className="mt-8">
                            <div className="md:flex md:items-center md:justify-center">

                                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                                    <div className="text-base font-medium text-gray-900">Szűcs Rebeka</div>

                                    <svg className="hidden md:block mx-1 h-5 w-5 text-indigo-600" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path d="M11 0h3L9 20H6l5-20z"/>
                                    </svg>

                                    <div className="text-base font-medium text-gray-500">Bobby gazdája</div>
                                </div>
                            </div>
                        </footer>

                    </blockquote>
                    <blockquote className="mt-10">
                        <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                            <p>
                                &ldquo;Nagyon aggódtam Lili egészsége miatt, és mindenképp szerettem volna
                                másodvéleményt kérni a laborleleteiről. Táplálási tanácsokkal is ellátott az "online
                                állatorvos", nagyon szuper kezdeményezésnek tartom!&rdquo;
                            </p>
                        </div>
                        <footer className="mt-8">
                            <div className="md:flex md:items-center md:justify-center">

                                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                                    <div className="text-base font-medium text-gray-900">Ludányi Annamária</div>

                                    <svg className="hidden md:block mx-1 h-5 w-5 text-indigo-600" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path d="M11 0h3L9 20H6l5-20z"/>
                                    </svg>

                                    <div className="text-base font-medium text-gray-500">Lili gazdája</div>
                                </div>
                            </div>
                        </footer>

                    </blockquote>
                </div>
            </div>
        </section>
    )
}
