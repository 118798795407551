import React from 'react';
import HeroSection from "./Components/HeroSection";
import Incentives from "./Components/Incentives";
import TwoColumnContent from "./Components/TwoColumnContent";
import Footer from "./Components/Footer";
import CTASection from "./Components/CTASection";
import ContentWithImage from "./Components/ContentWithImage";
import IconContent from "./Components/IconContent";
import Testimonials from "./Components/Testimonials";
import Team from "./Components/Team";

export default function App() {
    return (
        <>
            <HeroSection/>
            <Incentives/>
            <CTASection/>
            <Team/>
            <TwoColumnContent/>
            <CTASection/>
            <ContentWithImage/>
            <Testimonials/>
            <IconContent/>
            <Footer/>
        </>
    );

}
