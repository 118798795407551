import React from 'react';

const incentives = [
    {
        name: 'Válassz időpontot',
        imageSrc: '/img/calendar.svg',
        description: "Kattints a foglalás gombra, és válaszd ki a naptárból a számodra megfelelő időpontot!",
    },
    {
        name: 'Kényelmes fizetés, és pénz visszafizetési garancia',
        imageSrc: '/img/cash.svg',
        description: "Fizess kényelmesen, online bankkártyáddal! \n" +
            "\n" +
            "Amennyiben nem kerül sor konzultációra, mert a hívás elején kiderül, hogy nem lehet online megoldani a problémát, visszatérítjük a díját.  - csak 500 Ft kezelési költség kerül levonásra, mely az online fizetőrendszer díja. Lejjebb görgetve megtalálod, milyen esetekben fordulj online állatorvoshoz, és milyen esetekben vidd kedvenced azonnal rendelőbe.",
    },
    {
        name: 'Konzultáció',
        imageSrc: '/img/video.svg',
        description:
            "E-mailben fogod megkapni a videó híváshoz szükséges linket. Itt fogod tudni felvenni a kapcsolatot állatorvosunkkal a megadott időpontban.\n" +
            "\n" +
            "A konzultációs díj csak a hívás végeztével, a tanácsadás után kerül felszámolásra.",
    },
    /*  {
          name: 'Ha nem megoldható online',
          imageSrc: '/img/tick.svg',
          description: "Amennyiben nem kerül sor konzultációra, mert a hívás elején kiderül, hogy nem lehet online megoldani a problémát, további költséged nem lesz.",
      }, */
    /*    {
            name: '5-10 perc alatt megválaszolható kérdés\n' +
                '3500 Ft',
            imageSrc: '/img/tick.svg',
            description: "A konzultáció időtartama maximum 10 perc, díja 3500 Ft, amit átutalással tudsz majd kiegyenlíteni.\n" +
                "\n" +
                "A konzultációs díj csak a hívás végeztével, a tanácsadás után kerül felszámolásra.",
        },
        {
            name: '15-20 perc alatt megválaszolható\n' +
                'kérdés\n' +
                '7000 Ft',
            imageSrc: '/img/tick.svg',
            description:
                "A konzultáció időtartama maximum 20 perc, díja 7.000 Ft, amit átutalással tudsz majd kiegyenlíteni.\n" +
                "\n" +
                "A konzultációs díj csak a hívás végeztével, a tanácsadás után kerül felszámolásra.",
        },*/
    {
        name: '15 perces konzultáció',
        imageSrc: '/img/tick.svg',
        description: "Ha csak néhány kérdésed van az állatorvoshoz, vagy csak egy adott problémáról szeretnél információt gyűjteni, ezt a konzultációt javasoljuk. A foglalás az online fizetéssel válik aktívvá, majd e-mailt fogsz kapni a videohíváshoz szükséges csatlakozási linkkel. A lefoglalt időpontban lépj be a linkken a hívásba.",
    },
    {
        name: '30-40 perces konzultáció',
        imageSrc: '/img/tick.svg',
        description: "Ha több, összetett  kérdésed van az állatorvoshoz, vagy több egészségügyi problémáról szeretnél információt gyűjteni, ezt a konzultációt javasoljuk. A foglalás az online fizetéssel válik aktívvá, majd e-mailt fogsz kapni a videohíváshoz szükséges csatlakozási linkkel. A lefoglalt időpontban lépj be a linkken a hívásba.",
    },
    {
        name: 'Dietetikai tanácsadás',
        imageSrc: '/img/tick.svg',
        description: "Speciális táplálással kapcsolatos kérdésekben Dietetikus állatorvosunkhoz is tudsz időpontot foglalni hamarosan.",
    },
]

export default function Incentives() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
                <div className="bg-gray-50 rounded-2xl px-6 py-16 sm:p-16">
                    <div className="max-w-xl mx-auto lg:max-w-none">
                        <div className="text-center">
                            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                                Hogyan működik?
                            </h2>
                        </div>
                        <div
                            className="mt-12 max-w-sm mx-auto grid grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-3">
                            {incentives.map((incentive) => (
                                <div key={incentive.name}
                                     className="text-center sm:flex sm:text-left lg:block lg:text-center">
                                    <div className="sm:flex-shrink-0">
                                        <div className="flow-root">
                                            <img className="w-16 h-16 mx-auto" src={incentive.imageSrc} alt=""/>
                                        </div>
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                        <h3 className="text-xl font-medium text-gray-900">{incentive.name}</h3>
                                        <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
