import React from 'react';

export default function ContentWithImage() {
    return (
        <div className="relative bg-gray-800">
            <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                <img
                    className="w-full h-full object-cover"
                    src="/img/sickdog.webp"
                    alt=""
                />
            </div>
            <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                <div className="md:ml-auto md:w-1/2 md:pl-10">

                    <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Ha a következő
                        problémákat tapasztalod, ne minket hívj, hanem vidd állatorvosi rendelőbe kedvencedet!</p>

                    <ul role="list" className="my-10 text-xl leading-10 text-gray-300">
                        <li>Légzési nehézség</li>
                        <li>Nyílt seb</li>
                        <li>Szemproblémák</li>
                        <li>Ájulás</li>
                        <li>Felfúvódás</li>
                        <li>Súlyos hányás, hasmenés</li>
                        <li>Mérgezés</li>
                        <li>Idegen test, tárgyak lenyelése</li>
                    </ul>
                    <div className="mt-8">

                    </div>
                </div>
            </div>
        </div>
    )
}
