import React from 'react';

export default function TwoColumnContent() {
    return (
        <div className="bg-white overflow-hidden">
            <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"/>
                <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                    <div>

                        <h3 className="mt-2 sm:mt-20 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Milyen problémákkal fordulj hozzánk?
                        </h3>
                    </div>
                </div>
                <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                    <div className="relative lg:row-start-1 lg:col-start-2">
                        <svg
                            className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                          fill="currentColor"/>
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"/>
                        </svg>
                        <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                            <figure>
                                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                                    <img
                                        className="rounded-lg shadow-lg object-cover object-top"
                                        src="/img/kep1.webp"
                                        alt="Whitney leaning against a railing on a downtown street"
                                        width={1184}
                                        height={1376}
                                    />
                                </div>
                                {  /*<figcaption className="mt-3 flex text-sm text-gray-500">
                                    <CameraIcon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                                    <span className="ml-2">Photograph by Marcus O’Leary</span>
                                </figcaption> */}
                            </figure>
                        </div>
                    </div>
                    <div className="mt-8 lg:mt-0">

                        <div
                            className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">

                            <ul role="list" className="text-xl leading-10">
                                <li>Táplálási kérdések</li>
                                <li>Nevelési kérdések</li>
                                <li>Új háziállat érkezése</li>
                                <li>Emésztési problémák</li>
                                <li>Laboreredmények elemzése</li>
                                <li>Ha bizonytalan vagy kedvenced egészségügyi állapotával kapcsolatban</li>
                                <li>Viselkedési zavarok</li>


                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
