import React from 'react';
import {ExclamationIcon} from '@heroicons/react/solid';

export default function IconContent() {
    return (
        <div className="sm:flex lg:mx-56 mx-14 items-center justify-center my-10">
            <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">

                <ExclamationIcon className="-mr-1 ml-3 h-40 w-40 text-red-400"/>
            </div>
            <div>
                <h1 className="text-lg font-bold" style={{fontSize: 28}}>Figyelem!</h1>
                <p className="mt-1" style={{fontSize: 20}}>
                    A szolgáltatásunk nem távdiagnosztika! Tanácsadást, konzultációt nyújtunk kedvenceddel kapcsolatban
                    felmerülő kérdésekben. Ha kedvencednél valamilyen rendellenességet észlelsz, mihamarabb vizsgáltasd
                    meg egy állatorvosi rendelőben!
                </p>
            </div>
        </div>
    )
}
