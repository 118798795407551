import React from 'react';

const navigation = [
    {
        name: 'ÁSZF',
        href: '/doc/ASZF.pdf',
    },
    {
        name: 'GDPR',
        href: '/doc/GDPR.pdf',
    },


]

export default function Footer() {
    return (
        <footer className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                <div className="flex justify-center space-x-6 md:order-2">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href} target="_blank"
                           className="text-gray-500 hover:text-gray-600">
                            <span>{item.name}</span>

                        </a>
                    ))}
                </div>
                <div className="mt-8 md:mt-0 md:order-1">
                    <p className="text-center text-base text-gray-500">&copy; 2023 allatorvositanacs.hu</p>
                </div>
            </div>
        </footer>
    )
}
